import React from 'react';
import {Container} from '@material-ui/core';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Past from './components/Past/Past'
import DevolperInfo from './components/DevolperInfo/DevolperInfo';
import Graph from './components/Graph/Graph';

const App = () => {



    //this is the whole page of the app
    return (

        <BrowserRouter>
            <Container maxidth="lg">
                <Navbar />
                <Routes>
                    <Route exact path="/Home" element={<Home/>}/>
                    <Route exact path="/Past" element={<Past/>}/>
                    <Route exact path="/Graph" element={<Graph/>}/>
                    <Route exact path="/Dev"  element={<DevolperInfo/>}/> 
                </Routes>
            </Container>
        </BrowserRouter>
    );
}

export default App;